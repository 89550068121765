<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Invoice</h4>
</div>

<div class="modal-body ">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" id="form">
        <div class="row">
            <div class="col-6">

                <!-- Billing Adress -->
                <div class="address-container mb-2">
                    <div class="mb-2">
                        <label for="billingGroupName">Billing Group Name</label>
                        <input type="text" class="form-control" id="billingGroupName"
                            formControlName="billingGroupName" disabled>
                    </div>

                    <div class="mb-2">
                        <label for="bexioId">Bexio Id</label>
                        <input type="number" [min]="0" class="form-control" id="bexioId"
                            formControlName="bexioId" disabled>
                    </div>

                     <!-- Customer Order No. -->
                    <div class="mb-2">
                        <label for="customerOrderNumber">Customer order No.</label>
                        <input type="text" class="form-control" id="customerOrderNumber"
                            [ngClass]="validateField('customerOrderNumber')"
                            formControlName="customerOrderNumber">
                    </div>
                </div>
            </div>
            <div class="col-6">

                <!-- Invoice Date -->
                <div class="mb-2">
                    <label for="invoiceDate">Date</label>
                    <pmt-datepicker
                        formControlName="invoiceDate"
                        [id]="'invoiceDate'"
                        [showValidation]="validateField('invoiceDate') === 'is-invalid'">
                    </pmt-datepicker>
                </div>

                <!-- Payment term in days -->
                <div class="mb-2">
                    <div class="invoice-detail">
                        <label for="paymentTermInDays">Payment term in days</label>
                        <input type="number" class="form-control inlineInput" id="paymentTermInDays"
                        [ngClass]="validateField('paymentTermInDays')"
                        formControlName="paymentTermInDays">
                    </div>
                </div>
              
                <!-- Currency -->
                <div class="mb-2">
                    <label for="bankAccount">Bank account</label>
                    <select class="form-select" formControlName="bankAccount" [ngClass]="validateField('bankAccount')" id="bankAccount">
                        <option *ngFor="let bank of banks" [value]="bank.id">
                            {{bank.name}} - {{CurrencyEnum[bank.currency].toUpperCase()}}
                        </option>
                    </select>
                </div> 

                <!-- Connected With Worktimes -->
                <div class="mb-2 mt-3">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="connectedWithWorktimes" [ngClass]="validateField('connectedWithWorktimes')"
                        formControlName="connectedWithWorktimes">
                        <label for="connectedWithWorktimes">Connect with worktimes</label>
                    </div>
                </div> 
            </div>
        </div>
    </form>
</div>

<div class="modal-footer bg-dark text-light">
    <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
    <button type="submit" form="form" class="btn btn-success"><i class="fas fa-save"></i> Save</button>
</div>