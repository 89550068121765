import { Injectable } from '@angular/core';
import { AutoFocusFixDirective } from '@premotec/ngx-essentials';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridCustomerActionButtonsComponent } from './ag-grid-customer-action-buttons/ag-grid-customer-action-buttons.component';

@Injectable()
export class CustomerGridColumnService {
  constructor() { }
  async getColumns(): Promise<(ColDef | ColGroupDef)[]> {

    const cols: ColDef[] = [
      {
        field: 'name',
        headerName: 'Customer name',
        width:480
      },
      {
        field: 'visibleForBilling',
        headerName: 'Visible',
        filter: true,
        minWidth: 120,
        cellRenderer: (params) => {
            return `<input type="checkbox" ${params.value ? 'checked' : ''} disabled />`;
        }
      },
      {
        field: 'editButton',
        headerName: '',
        filter: false,
        sortable: false,
        cellRenderer: AgGridCustomerActionButtonsComponent,
        cellEditorPopupPosition: 'under',
        tooltipField: "edit",
        minWidth: 75, 
        maxWidth: 75, 
        pinned: 'right'
      },
    ]

    return cols
  }

}