import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { CustomerModel } from "../models/customer/customer.model";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ProjectModel } from "../models/project/project.model";
import { EmployeeModel } from "../models/employee/employee.model";

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    constructor(private http: HttpClient) { }

    public getCustomers(): Observable<CustomerModel[]> {
        return this.http.get<CustomerModel[]>(`${environment.api}/customer`);
    }

    public updateCustomer(id: string, customer: CustomerModel): Observable<CustomerModel> {
        return this.http.put<CustomerModel>(`${environment.api}/customer/${id}`, customer);
    }

    public getCustomerDetails(id: string): Observable<CustomerModel> {
        return this.http.get<CustomerModel>(`${environment.api}/customer/${id}`);
    }

    public getCustomerProjects(id: string): Observable<ProjectModel[]> {
        return this.http.get<ProjectModel[]>(`${environment.api}/customer/${id}/projects`);
    }

}