import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxDatepickerModule } from '@premotec/ngx-datepicker';
import { ImportComponent } from './components/import/import.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ProjectGroupOverviewComponent } from './components/project-group/overview/project-group-overview.component';
import { ProjectGroupGridColumnService } from './components/project-group/overview/project-group-grid-column.service';
import { AgGridProjectGroupActionButtonsComponent } from './components/project-group/overview/ag-grid-project-group-action-buttons/ag-grid-project-group-action-buttons.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridSharedModule } from '../shared/ag-grid-shared/ag-grid-shared.module';
import { ProjectGroupCreateModalComponent } from './components/project-group/project-group-create/project-group-create.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { UpdateNameAndStatusModalComponent } from './components/project-group/project-group-details/update-name-and-status-modal/update-name-and-status-modal.component';
import { ProjectGroupDetailsComponent } from './components/project-group/project-group-details/project-group-details.component';
import { RouterModule } from '@angular/router';
import { TimetrackerProjectsComponent } from './components/project-group/project-group-details/timetracker-projects/timetracker-projects.component';
import { GitLabProjectsComponent } from './components/project-group/project-group-details/gitlab-projects/gitlab-projects.component';
import { UpdateTimetrackerProjectsModalComponent } from './components/project-group/project-group-details/timetracker-projects/update-timetracker-projects-modal/update-timetracker-projects-modal.component';
import { UpdateGitlabProjectsModalComponent } from './components/project-group/project-group-details/gitlab-projects/update-gitlab-projects-modal/update-gitlab-projects-modal.component';
import { FilterPipe } from './components/project-group/project-group-details/projects-search-by-name.pipe';
import { IssueOverviewComponent } from './components/issue/overview/issue-overview.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EstProjectCostsOverviewColumnService } from './components/est-project-costs/overview/est-project-costs-overview-grid-column.service';
import { EstProjectCostsService } from './services/est-project-costs.service';
import { AgGridEditButtonComponent } from './components/est-project-costs/overview/ag-grid-edit-button/ag-grid-edit-button.component';
import { EstProjectCostsOverviewComponent } from './components/est-project-costs/overview/est-project-costs-overview.component';
import { CreateEstProjectCostsModalComponent } from './components/est-project-costs/overview/create-est-project-costs-modal/create-est-project-costs-modal.component';
import { EstProjectCostsDetailsComponent } from './components/est-project-costs/est-project-costs-detail/est-project-costs-details.component';
import { UpdateEstProjectCostsModalComponent } from './components/est-project-costs/est-project-costs-detail/update-est-project-costs-modal/update-est-project-costs-modal.component';
import { AgGridUpdateButtonComponent } from './components/est-project-costs/overview/ag-grid-update-button/ag-grid-update-button.component';
import { IssueSearchbarComponent } from './components/issue/overview/issue-searchbar/issue-searchbar.component';
import { ProjectStatusOverviewComponent } from './components/project-status/overview/project-status-overview.component';
import { IssueProjectGroupsModalComponent } from './components/issue/issue-project-groups-modal/issue-project-groups-modal.component';
import { SharedModule } from "../shared/shared.module";
import { PlanningComponent } from './components/management/planning/planning.component';
import { PlanningSearchbarComponent } from './components/management/planning/planning-searchbar/planning-searchbar.component';
import { BillingSummaryComponent } from './components/management/billing-summary/billing-summary.component';
import { BillingSummarySearchbarComponent } from './components/management/billing-summary/billing-summary-searchbar/billing-summary-searchbar.component';
import { EvaluationSearchbarComponent } from './components/management/evaluation/evaluation-searchbar/evaluation-searchbar.component';
import { EvaluationComponent } from './components/management/evaluation/evaluation.component';
import { EditInvoiceModalComponent } from './components/management/invoice/invoice-detail/edit-invoice-modal/edit-invoice-modal.component';
import { CreateInvoiceModalComponent } from './components/management/invoice/overview/create-invoice-modal/create-invoice-modal.component';
import { InvoiceOverviewComponent } from './components/management/invoice/overview/invoice-overview.component';
import { InvoiceDetailsComponent } from './components/management/invoice/invoice-detail/invoice-details.component';
import { CreateOrUpdatePositionModalComponent } from './components/management/invoice/invoice-detail/createOrUpdate-position-modal/createOrUpdate-position-modal.component';
import { AgGridInfoButtonComponent } from './components/management/invoice/overview/ag-grid-info-button/ag-grid-info-button.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AgGridInvoiceEditButtonComponent } from './components/management/invoice/overview/ag-grid-edit-button/ag-grid-invoice-edit-button.component';
import { ProjectService } from './services/project.service';
import { InvoiceOverviewColumnService } from './components/management/invoice/overview/invoice-overview-grid-column.service';
import { InvoiceService } from './services/invoice/invoice.service';
import { BillingGroupService } from './services/billing-group.service';
import { BillingGroupOverviewComponent } from './components/management/billing-group/billing-group-overview/billing-group-overview.component';
import { BillingGroupDetailsComponent } from './components/management/billing-group/billing-group-details/billing-group-details.component';
import { InvoiceInformationComponent } from './components/management/billing-group/billing-group-details/invoice-information/invoice-information.component';
import { BillingGroupUpdateInvoiceInformationModalComponent } from './components/management/billing-group/billing-group-details/invoice-information/update-invoice-information-modal/update-invoice-information-modal.component';
import { EmailSettingsComponent } from './components/management/billing-group/billing-group-details/email-settings/email-settings.component';
import { UpdateEmailSettingsModalComponent } from './components/management/billing-group/billing-group-details/email-settings/update-email-settings-modal/update-email-settings-modal.component';
import { BillingGroupCreateComponent } from './components/management/billing-group/billing-group-create/billing-group-create.component';
import { AgGridActionButtonsComponent } from './components/management/billing-group/billing-group-overview/ag-grid-action-buttons/ag-grid-action-buttons.component';
import { BillingGroupUpdateNameAndStatusModalComponent } from './components/management/billing-group/billing-group-details/update-name-and-status-modal/update-name-and-status-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BillingGroupGridColumnService } from './components/management/billing-group/billing-group-overview/billing-group-grid-column.service';
import { EmployeeOverviewComponent } from './components/management/employee/employee-overview.component';
import { AgGridEmployeeActionButtonsComponent } from './components/management/employee/ag-grid-employee-action-buttons/ag-grid-employee-action-buttons.component';
import { EditEmployeeDetailsModalComponent } from './components/management/employee/edit-employee-details-modal/edit-employee-details-modal.component';
import { ProjectOverviewComponent } from './components/management/project/project-overview.component';
import { EditProjectDetailsModalComponent } from './components/management/project/edit-project-details-modal/edit-project-details-modal.component';
import { CompleteProjectPipe } from '../shared/pipes/complete-project.pipe';
import { CustomerService } from './services/customer.service';
import { CustomerOverviewComponent } from './components/management/customer/overview/customer-overview.component';
import { CustomerDetailsComponent } from './components/management/customer/customer-details/customer-details.component';
import { AgGridCustomerActionButtonsComponent } from './components/management/customer/overview/ag-grid-customer-action-buttons/ag-grid-customer-action-buttons.component';
import { EditCustomerDetailsModalComponent } from './components/management/customer/customer-details/edit-customer-details-modal/edit-customer-details-modal.component';
import { ActiveProjectPipe } from '../shared/pipes/active-project.pipe';
import { CustomerGridColumnService } from './components/management/customer/overview/customer-grid-column.service';

@NgModule({
    declarations: [
        ImportComponent,
        ProjectGroupOverviewComponent,
        AgGridProjectGroupActionButtonsComponent,
        ProjectGroupCreateModalComponent,
        UpdateNameAndStatusModalComponent,
        UpdateEstProjectCostsModalComponent,
        ProjectGroupDetailsComponent,
        TimetrackerProjectsComponent,
        GitLabProjectsComponent,
        UpdateTimetrackerProjectsModalComponent,
        UpdateGitlabProjectsModalComponent,
        FilterPipe,
        IssueOverviewComponent,
        AgGridEditButtonComponent,
        AgGridUpdateButtonComponent,
        EstProjectCostsOverviewComponent,
        CreateEstProjectCostsModalComponent,
        EstProjectCostsDetailsComponent,
        IssueSearchbarComponent,
        PlanningSearchbarComponent,
        ProjectStatusOverviewComponent,
        IssueProjectGroupsModalComponent,
        PlanningComponent,
        BillingSummaryComponent,
        BillingSummarySearchbarComponent,
        EvaluationSearchbarComponent,
        EvaluationComponent,
        EditInvoiceModalComponent,
        CreateInvoiceModalComponent,
        InvoiceOverviewComponent,
        InvoiceDetailsComponent,
        CreateOrUpdatePositionModalComponent,
        AgGridInvoiceEditButtonComponent,
        AgGridInfoButtonComponent,
        BillingGroupOverviewComponent,
        BillingGroupDetailsComponent,
        AgGridActionButtonsComponent,
        InvoiceInformationComponent,
        BillingGroupUpdateInvoiceInformationModalComponent,
        BillingGroupUpdateNameAndStatusModalComponent,
        EmailSettingsComponent,
        UpdateEmailSettingsModalComponent,
        BillingGroupCreateComponent,
        EmployeeOverviewComponent,
        AgGridEmployeeActionButtonsComponent,
        EditEmployeeDetailsModalComponent,
        ProjectOverviewComponent,
        EditProjectDetailsModalComponent,
        CompleteProjectPipe,
        CustomerOverviewComponent,
        CustomerDetailsComponent,
        AgGridCustomerActionButtonsComponent,
        EditCustomerDetailsModalComponent,
        ActiveProjectPipe
    ],
    providers: [
        ProjectGroupGridColumnService,
        ReactiveFormsModule,
        ModalModule,
        FormsModule,
        EstProjectCostsService,
        EstProjectCostsOverviewColumnService,
        DatePipe,
        ProjectService,
        InvoiceService,
        BillingGroupService,
        InvoiceOverviewColumnService,
        BillingGroupGridColumnService,
        CustomerService,
        CustomerGridColumnService
    ],
    imports: [
        CommonModule,
        NgxDatepickerModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,
        AgGridSharedModule,
        RouterModule,
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        DragDropModule,
        CommonModule,
        SharedModule,
        NgSelectModule
    ]
})
export class ProjectInsightsModule { }
