<div class="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark custom-sidebar" [ngClass]="collapsedSidebar ? 'sidebar-collapsed-width' : 'sidebar-width'" pmtAutoWindowHeight>
    <ul class="nav nav-pills flex-column mb-auto">
      <!-- Basic Data -->
      <app-section [collapsedSidebar]="collapsedSidebar" title="Basic Data" iconCode="text-muted h5 fw-normal" collapseType="person">
        <app-link-item
        routerLinkInput="project-group"
        translocoText="Project Groups"
        iconCode="fas fa-users"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <!--<app-link-item
        routerLinkInput="est-project-costs"
        translocoText="Project Cost Reports"
        iconCode="fas fa-file-invoice-dollar"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>-->
      </app-section>

      <!-- Issue statistics -->
      <app-section [collapsedSidebar]="collapsedSidebar" title="Issue statistics" iconCode="fa fa-pie-chart" collapseType="person">
        <app-link-item
          routerLinkInput="issues"
          [translocoText]="collapsedSidebar ? 'Issues' : 'Issues'"
          iconCode="fas fa-folder-open"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="project-status"
          [translocoText]="collapsedSidebar ? 'Project status' : 'Project status'"
          iconCode="fas fa-list-check"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
      </app-section>

      <!-- Data transfer -->
      <app-section [collapsedSidebar]="collapsedSidebar" title="Data transfer" iconCode="fa-solid fa-arrow-right-arrow-left fa-xs" collapseType="person">
        <app-link-item
          routerLinkInput="import"
          [translocoText]="collapsedSidebar ? 'Import' : 'Import'"
          iconCode="fas fa-file-import"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="hangfire"
          translocoText="Hangfire"
          iconCode="fas fa-clock"
          [translocoText]="collapsedSidebar ? 'Hangfire' : 'Hangfire'"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
      </app-section>  

      <!-- Management -->
      <app-section *ngIf="hasAccess" [collapsedSidebar]="collapsedSidebar" title="Management" iconCode="fa-solid fa-chart-line fa-xs" collapseType="person">
        <app-link-item
          routerLinkInput="planning"
          translocoText="Planning"
          iconCode="fa-solid fa-chart-simple"
          [translocoText]="collapsedSidebar ? 'Planning' : 'Planning'"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="billingSummary"
          translocoText="Summary"
          iconCode="fa-list-alt fas"
          [translocoText]="collapsedSidebar ? 'Summary' : 'Summary'"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <!--<app-link-item
          routerLinkInput="evaluation"
          translocoText="Evaluation"
          iconCode="fa-solid fa-hourglass"
          [translocoText]="collapsedSidebar ? 'Evaluation' : 'Evaluation'"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>-->
        <app-link-item
            routerLinkInput="invoices"
            translocoText="Invoices"
            iconCode="fas fa-file-invoice-dollar"
            [translocoText]="collapsedSidebar ? 'Invoices' : 'Invoices'"
            [collapsedSidebar]="collapsedSidebar">    
        </app-link-item>
        <app-link-item
          routerLinkInput="billing-groups"
          translocoText="Billing Groups"
          iconCode="fas fa-users"
          [translocoText]="collapsedSidebar ? 'Billing Groups' : 'Billing Groups'"
          [collapsedSidebar]="collapsedSidebar">    
        </app-link-item>
        <app-link-item
          routerLinkInput="employees"
          translocoText="Employees"
          iconCode="fa-solid fa-users"
          [translocoText]="collapsedSidebar ? 'Employees' : 'Employees'"
          [collapsedSidebar]="collapsedSidebar">    
        </app-link-item>
        <app-link-item
          routerLinkInput="projects"
          translocoText="Projects"
          iconCode="fas fa-folder-open"
          [translocoText]="collapsedSidebar ? 'Projects' : 'Projects'"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
        <app-link-item
          routerLinkInput="customers"
          translocoText="Customers"
          iconCode="fas fa-building-user"
          [translocoText]="collapsedSidebar ? 'Customers' : 'Customers'"
          [collapsedSidebar]="collapsedSidebar">
        </app-link-item>
      </app-section>  
    </ul>
  
    <div [ngClass]="!collapsedSidebar ? 'custom-position' : 'custom-position-collapsed'">
      <hr>
      <a (click)="toggleNavbar()" class="cursor-pointer text-white text-center">
        <div *ngIf="!collapsedSidebar">
          <i class="fas fa-chevron-left custom-margin-right"></i>
          <i class="fas fa-chevron-left"></i>
          <span *ngIf="!collapsedSidebar"> Einklappen</span>
        </div>
        <div *ngIf="collapsedSidebar">
          <i class="fas fa-chevron-right custom-margin-right"></i>
          <i class="fas fa-chevron-right"></i>
        </div>
      </a>
    </div>
  </div>