<div class="modal-header bg-dark text-light">
    <h4 class="modal-title pull-left">Edit Project</h4>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
        <div>
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <label>Select a billing group</label>
                        <select class="form-select" name="billingGroup" formControlName="billingGroupId">
                            <option [ngValue]="null"> </option>
                            <option *ngFor=" let billingGroup of billingGroups" [value]="billingGroup.id">{{billingGroup.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <label for="hourlyRateInput">Hourly rate project</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">CHF</span>
                            <input type="text" class="form-control" id="hourlyRateInput" formControlName="hourlyRate" appTwoDecimalNumber>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-check form-switch">
                <input class="form-check-input" id="isActive" type="checkbox" role="switch" formControlName="isActive" [value]="project.isActive">
                <label>Active</label>
            </div>

        </div>

    </div>
    <div class="modal-footer bg-dark text-light">
        <button type="button" class="btn btn-light" (click)="decline()">Cancel</button>
        <button type="submit" form="form" class="btn btn-success" (click)="onSubmit()"><i class="fas fa-save"></i> Save</button>
    </div>
</form>

