import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { PmtUserRole } from '../models/pmt-user-role.model';

@Injectable({
  providedIn: 'root'
})
export class ManagementAuthGuard implements CanActivate {

  private readonly allowedRoles = ['Project Management', 'Accounting'];

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return this.authService.getUserRolesById().pipe(
      map((roles: PmtUserRole[]) => this.hasAccess(roles) || this.redirectToNotAuthorized()),
      catchError(() => of(this.redirectToNotAuthorized()))
    );
  }

  private hasAccess(roles: PmtUserRole[]): boolean {
    return roles.some(role => this.allowedRoles.includes(role.name));
  }

  private redirectToNotAuthorized(): UrlTree {
    return this.router.createUrlTree(['/not-authorized']);
  }
}
