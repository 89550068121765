<ng-container *ngIf="!loading">
    <div class="container-fluid mt-3">
        <div class="card mb-3 border-bottom-0">
            <div class="card-header">
                <div class="form-group">
                    <div class="btn-group me-2">
                        <button class="btn btn-light border me-2" type="button" [routerLink]="['/customers']"
                            tooltip="Back" [delay]="300" placement="bottom">
                            <i class="fas fa-arrow-left"></i>
                        </button>
                    </div>

                    <div class="switch-container btn-group me-2">
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="activeSwitch" type="checkbox" role="switch"
                                [(ngModel)]="showInactiveProjects" unchecked>
                        </div>
                        <label for="activeSwitch" class="form-check-label">Show Inactive</label>
                    </div>
                </div>

            </div>
        </div>

        <div class="d-flex pb-2 ">
            <h2 class="pe-3">
                Customer - {{customer.name}}
            </h2>
        </div>

        <div class="row">
            <div class="col-sm">
                <div class="switch-container btn-group me-2 m-2">
                    <div class="form-check form-switch">
                        <input class="form-check-input" id="visibleSwitch" type="checkbox" role="switch"
                            [(ngModel)]="customer.visibleForBilling"  (click)="changeVisibility(customer, customer.visibleForBilling)" unchecked>
                        <label for="visibleSwitch" class="form-check-label">Visible</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex pb-2 pt-3">
            <h3 class="pe-3">
                Projects
            </h3>
        </div>

        <div class="container-flat">
            <div class="row">
                <div class="col-sm">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="col-switch">Active</th>
                                <th>Project</th>
                                <th>Billing Group</th>
                                <th>Hourly rate project</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let project of projects | activeProject : showInactiveProjects">
                                <td>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" [(ngModel)]="project.isActive" disabled>
                                    </div>
                                </td>
                                <td>{{project.name}}</td>
                                <td>{{project.billingGroup?.name}}</td>
                                <td>{{project.hourlyRate | number: '1.2-2' }}</td>
                                <td>
                                    <button (click)="updateProject(project)" type="button"
                                        class="btn btn-sm btn-primary float-end">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="loading">
    <div class="loading-container">
        <div class="loading-spinner">
            <div class="spinner-grow text-secondary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</ng-container>